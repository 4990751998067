<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'monster-family',
  primary_color: '#95AD25',
  secondary_color: '#0071BD',
  header: {
    background: {
      image: `/projekt/monster-family/elbworx-projekt-pass-sql-header-3840.webp`,
    },
    project: 'PASS Deutschland e.V.',
    headline: 'Monster Family',
    headline_low_contrast_fix: true,
    info_card: {
      description:
        'PASS Deutschland e.V. ist die deutsche Microsoft Data Platform Community und das Zuhause des deutschen Datamonster',
      client: 'PASS Deutschland e.V.',
      roles: ['Illustration', 'Veranstaltungsdesign'],
      project_duration: '2016 bis 2023',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3 class="!text-secondary">Die Herausforderung</h3>

      <p>
        Der Verein PASS Deutschland e.V. ist eine Gemeinschaft von Datenprofis, welcher sich dem
        Wissensaustausch, der Weiterbildung und der Vernetzung widmet. Ihre Mission dreht sich
        rundum SQL und die vielfältigen Aspekte der Microsoft-Datenplattformen, sei es die neuesten
        Dienste, Tools oder Komponenten. Wir begleiten sie dabei seit 2016 und kümmern uns um die
        grafische Ausarbeitung ihrer Veranstaltungen.
      </p>
      <p>
        Dabei ist das Data Monster, ursprünglich als Maskottchen für die SQL Server Konferenz
        entworfen, zu deren Markenzeichen geworden. Es repräsentiert die Herausforderungen, die mit
        Datenwachstum und -komplexität einhergehen. „Tame the Data Monster“ ist nicht nur ein Motto,
        sondern eine Einstellung, sich den datenbezogenen Herausforderungen zu stellen und stets
        nach innovativen Lösungen zu suchen. Im Laufe der Jahre hat sich die Welt des Data Monsters
        erweitert und vielfältige Formen angenommen. Das Data Monster hat nicht nur Gesellschaft
        bekommen, sondern eine ganze Familie von Maskottchen, die die unterschiedlichen Facetten der
        Datenwelt repräsentieren.
      </p>
    </template>

    <Padding>
      <Text>
        <h3 class="!text-secondary">Von der Skizze zum Cinematic Universe</h3>
      </Text>
    </Padding>
    <Padding>
      <Grid class="items-end">
        <div class="col-span-6">
          <Image
            class="w-full object-cover"
            :src="`${projectPath}elbworx-projekt-pass-sql-skizze1-1920.webp`"
          />
        </div>
        <div class="col-span-6">
          <Image
            class="w-full object-cover"
            :src="`${projectPath}elbworx-projekt-pass-sql-skizze2-1920.webp`"
          />
        </div>
      </Grid>
    </Padding>
    <Padding class="mt-20">
      <Grid class="items-end">
        <div class="col-span-6">
          <Image
            class="w-full object-cover"
            :src="`${projectPath}elbworx-projekt-pass-sql-skizze3-1920.webp`"
          />
        </div>
        <div class="col-span-6">
          <Image
            class="w-full object-cover"
            :src="`${projectPath}elbworx-projekt-pass-sql-skizze4-1920.webp`"
          />
        </div>
      </Grid>
    </Padding>

    <div class="mt-20">
      <Image
        class="w-full object-cover"
        :src="`${projectPath}elbworx-projekt-pass-sql-poster-1920.webp`"
      />
    </div>
    <div class="mt-20">
      <Image
        class="w-full object-cover"
        :src="`${projectPath}elbworx-projekt-pass-sql-business-cards_envelopes-3000.webp`"
      />
    </div>

    <Tilt class="mt-16">
      <Image
        class="w-full object-cover"
        :src="`${projectPath}elbworx-projekt-pass-sql-macbook_on_couch-2560_LQ85.webp`"
      />
    </Tilt>
    <div class="-mt-tilt">
      <Image
        class="w-full object-cover"
        :src="`${projectPath}elbworx-projekt-pass-sql-shoulder_bag-2560.webp`"
      />
    </div>

    <Grid class="mt-20 items-start">
      <div class="col-span-6">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-pass-sql-sticker-1920.webp`"
        />
      </div>
      <Padding class="md:contents">
        <div class="col-span-6">
          <Padding only="right" class="contents md:block">
            <Text>
              <h3 class="!text-secondary">Merch Merch Merch!</h3>
              <p>
                Die alljährlichen Konferenzen sind ein Highlight, bei dem nicht nur wichtige Themen
                besprochen werden, sondern auch eine Gelegenheit für uns, exklusive
                Merchandise-Artikel und begehrte Giveaways zu kreieren. Dies umfasst die Schaffung
                von frischen Postern, ansprechenden Einladungen, markanten Key Visuals sowie
                trendigen Taschen und Aufklebern.
              </p>
            </Text>
          </Padding>
        </div>
      </Padding>
    </Grid>
  </Project>
</template>
